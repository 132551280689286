




import {Component} from 'vue-property-decorator';
import BaseNavigationPage from '@/components/BaseNavigationPage.vue';
import {settingsStore} from '@/store/modules/settings';
import {mixins} from 'vue-class-component';
import Navigation from '@/mixins/Navigation';

Component.registerHooks(['beforeRouteEnter'])

@Component({
    name: 'settings',
    components: {BaseNavigationPage}
})
export default class Settings extends mixins(Navigation) {

    get navItems() {
        const name = this.$route.name
        return [
            {
                title: 'Localization',
                type: 'localisation',
                name: 'settings-localisation',
                selected: name === 'settings-localisation'
            },
            {
                title: 'Schedules',
                type: 'schedules',
                name: 'settings-schedules',
                selected: name === 'settings-schedules'
            },
            {
                title: 'Notifications',
                type: 'notifications',
                name: 'settings-notifications',
                selected: name === 'settings-notifications'
            },
            {
              title: 'Away messages',
              type: 'away-messages',
              name: 'settings-away-messages',
              selected: name === 'settings-away-messages'
            },
            {
                title: 'Backup',
                type: 'backup',
                name: 'settings-backup',
                selected: name === 'settings-backup'
            },
            {
                title: 'Appointments',
                type: 'appointments',
                name: 'settings-appointments',
                selected: name === 'settings-appointments'
            },
            {
              title: 'Contacts',
              type: 'contacts',
              name: 'settings-contacts',
              selected: name === 'settings-contacts'
            },
            {
              title: 'Business page access',
              type: 'access',
              name: 'settings-page-access',
              selected: name === 'settings-page-access'
            },
            {
              title: 'Pigeon Live Chat',
              type: 'live-chat',
              name: 'settings-live-chat',
              selected: name === 'settings-live-chat'
            }
        ];
    }

    public onNavItemSelected(navItem) {
        this.$router.push({name: navItem.name, params: {}});
    }

    public created() {
        this.selectPage(7);
    }

    public async beforeRouteEnter(to, from, next) {
        await settingsStore.readSettings();
        next()
    }
}
